footer {
    background: var(--color-primary);
    padding: 3rem 0;
    text-align: center;
    font-size: .9rem;
    margin-top: 7rem;

}

footer a {
    color: var(--color-bg);

}

.footer__logo {
    font-size: 1.8rem;
    font-weight: 500;
    margin-bottom: 2rem;
    display: inline-block;
}

.permalinks {
    display: flex;
    flex: wrap;
    justify-content: center;
    gap: 2rem;
    margin: 0 auto 3rem;
    margin-bottom: 4rem;
    
}

.footer__socialicons {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 4rem;
}

.footer__socialicons a {
    background: var(--color-bg);
    color: var(--color-white);
    padding: 0.8rem;
    border-radius: .6rem;
    display: flex;
    border: 1px solid transparent;

}

.footer__socialicons a:hover {
    background: transparent;
    color: var(--color-bg);
    border-color: var(--color-bg);

}

.footer__copyright {
    margin-bottom: 4rem;
    color: var(--color-bg);

}

/* Media Queries (SM) */

@media screen and (max-width: 600px) {
    .permalinks {
        flex-direction: column;
        gap: 1.3rem;

    }

    .footer__socialicons {
        margin-bottom: 2.6rem;
    }
}